// Components/ui
import { Box, useTheme } from '@mui/material';
import {
  Calendar as BigCalendar,
  CalendarProps,
  momentLocalizer,
} from 'react-big-calendar';
import moment from 'moment';
// Lenguage
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import 'moment/locale/es';
// Context
import { useContext, useMemo } from 'react';
import { CalendarContext } from '../../context/CalendarContext';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { style } from './style';
// import './style.css';

let localizer = momentLocalizer(moment);

i18n.on('languageChanged', (lng) => {
  if (lng === 'ES') {
    moment.locale('es');
  } else if (lng === 'En') {
    moment.locale('en');
  }
  localizer = momentLocalizer(moment);
});

export type Appointment = {
  id?: number;
  status?: string;
  location?: string;
  resource?: string;
  address?: string;
  start: string | Date;
  end: string | Date;
};

interface CalendarProps {
  onShowAppointmentView?: (appointment: Appointment) => void;
}

const Calendar = ({ onShowAppointmentView }: CalendarProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    events: { events },
    handleSelectEvent,
  } = useContext(CalendarContext);

  const formattedEvents = useMemo(() => 
    events.map(event => ({
      ...event,
      start: new Date(event.start),
      end: new Date(event.end)
    })), [events]);
  // const components = {
  //   event: ({ event }: { event: any }) => {
  //     const data = event?.data;
  //     if (data?.appointment)
  //       return (
  //         <AppointmentEvent
  //           appointment={data?.appointment}
  //           onDoubleClick={() => {}}
  //         />
  //       );

  //     return null;
  //   },
  // };

  // const { data } = useAppointments();

  // const appointments = data?.map((appointment) => ({
  //   start: new Date(appointment.start),
  //   end: new Date(appointment.end),
  //   data: { appointment },
  // }));

  return (
    <Box
      sx={
       style.calendar
      }
    >
      <BigCalendar
        events={formattedEvents}
        selectable
        // popup
        onSelectSlot={(event) => {
          console.log(event);
        }}
        onDoubleClickEvent={(event) => {
          event && handleSelectEvent(event);
        }}
        messages={{
          next: t("global.next"),
          previous: t("global.back"),
          today: t("global.today"),
          month: t("global.month"),
          week: t("global.week"),
          day: t("global.day"),
        }}
        localizer={localizer}
        views={["month", "week", "day"]}
      />
    </Box>
  );
};

export default Calendar;
